<template>
  <div>
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-11">
              <h1>
                <b-icon icon="person-lines-fill" /> Roles de {{ personName }}
              </h1>
            </div>
            <div class="col-1">
              <h1>
                <b-icon
                  type="button"
                  @click="toUsers"
                  icon="arrow-return-left"
                />
              </h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="container col-12">
            <div>
              <UserRolesRegister :id="id" />
              <b-table
                striped
                responsive
                stacked="lg"
                :items="desserts"
                :fields="fields"
              >
                <template #cell(relationState)="row">
                  <b-badge
                    pill
                    :variant="returnColor(row.item.relationState)"
                    >{{ returnData(row.item.relationState) }}</b-badge
                  >
                </template>
                <template #cell(actions)="row">
                  <b-icon-trash
                    type="button"
                    size="sm"
                    class="mx-1"
                    @click="modalDelete(row.item)"
                  ></b-icon-trash>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Mensaje alerta eliminacion-->

    <div>
      <b-modal centered v-model="showDelete" title="Mensaje">
        <b-container fluid>
          <!--Contenido modal-->
          <h5 class="text-center">
            ¿Seguro que desea quitar el rol <b>{{ data.name }}</b
            >?
          </h5>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="deleteItem(dataDelete)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Aceptar
            </button>
            <button
              type="button"
              @click="exitDelete()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import UserRolesRegister from "../File-user/Users-rols-register.vue";
export default {
  components: { UserRolesRegister },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    dataDelete: "",
    showDelete: false,
    personName: "",
    data: {
      id: -1,
      name: "",
      lastnameFather: "",
      lastnameMother: "",
      dateRegister: "",
    },
    fields: [
      { key: "roleName", label: "Nombre" },
      { key: "relationState", label: "Estado" },
      { key: "actions", label: "Acciones" },
    ],
    desserts: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    returnData(data) {
      if (data === "A") return "Activo";
    },
    returnColor(data) {
      if (data === "A") return "success";
    },
    exitDelete() {
      this.showDelete = false;
      this.data.name = "";
    },
    modalDelete(data) {
      this.showDelete = true;
      this.dataDelete = data;
      this.data.name = data.roleName;
    },
    toUsers() {
      this.$router.push("/person");
    },
    async initialize() {
      await this.$axios
        .get("/person/" + this.id + "/role", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.personName = res.data.name;
          var aux = [];
          for (var i in res.data.roles) {
            aux.push(res.data.roles[i]);
          }
          this.desserts = aux;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async deleteItem(item) {
      this.showDelete = false;
      if ((item != "") & (this.id != null)) {
        try {
          await this.$axios
            .get("/person/" + this.id + "/role-delete?id=" + item.roleId, {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            })
            .then((res) => {
              this.$message.success(res.data.content);
              this.initialize();
            });
        } catch (e) {
          this.$message.error("Error!");
          console.log(e);
        }
      }
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
</style>