<template>
  <div id="RolesRegister">
    <button
      @click="addRol()"
      style="background-color: #1d425e; color: white"
      class="btn mb-3"
    >
      Agregar Rol
    </button>
    <div>
      <b-modal scrollable centered v-model="show" title="Agregar Rol">
        <b-container fluid>
          <form>
            <div class="form-group">
              <label for="exampleInputEmail1">Seleccionar rol</label>
              <b-form-select
                class="form-control"
                v-model="data.idTwo"
                :options="roles"
                required
              ></b-form-select>
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="saveAddItem()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Agregar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "RolesRegister",
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      data: {
        idTwo: null,
        idOne: null,
      },
      roles: [],
    };
  },
  methods: {
    exit() {
      this.show = false;
    },
    addRol() {
      this.listRols();
      (this.data = {
        idTwo: null,
        idOne: parseInt(this.id),
      }),
        (this.show = true);
    },
    async listRols() {
      await this.$axios
        .get("/person/" + this.id + "/role-register?id=", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.roles = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async saveAddItem() {
      await this.$axios
        .get("/person/" + this.id + "/role-register?id=" + this.data.idTwo, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.show = false;
          this.$message.success(res.data.content);
          this.$parent.initialize();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          this.$message.error("Error. Seleccione rol valido.");
        });
    },
  },
};
</script>
<style>
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>